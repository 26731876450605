import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import './home_crousel.css'

import {Grid, } from '@mui/material';




const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};



export default function D6_Home_SCarousel(props) {
const {blogData}=props
  var settings = {
    dots: true,
    infinite: true,
  
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />
  };


  return (


      <div className="D6_Home_SC_Slider_Div">
    <Slider {...settings}    easing='linear' lazyLoad fade  >
    {blogData.filter((val,idx)=>val.active===true&&idx<5).sort((a,b)=>a.id<b.id?1:-1).map((item,idx) => { 
    return(
    <div onClick={()=>window.location.href=`/${item.urlName}`} key={idx} className="D6_Home_Img1_div1">
   
        <Grid container >
          <Grid item sm={12} xs={12} style={{backgroundImage:`linear-gradient(45deg,rgba(0,0,0,0.4), transparent),url(${item.image})`}}  className='blog_bgC'> 
         
         <div class="max-width">
          <div class="crousel-content">
          <h2>{item.valueHead}</h2>
            <p>
              by {item.createdBy}
            <p style={{marginTop:'15px'}}><span> Created On: {item.createdOn}</span><br></br>
            <span> Views: {item.views}</span></p>
            </p>
           
          </div>
        </div>
          </Grid>
        </Grid>
    
 
         
        </div>)  })}
 
      
       
    </Slider>
    </div>
  );
}